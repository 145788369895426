<template>
  <div
    class="d-flex justify-content-center align-items-center auth-wrapper auth-v1"
  >
    <b-row>
      <b-col>
        <b-card class="mb-1">
          <b-link class="brand-logo ml-1">
            <!-- logo -->
            <kultiver-logo />
            <h2 class="brand-text text-primary ml-1">
              Kultiver
            </h2>
          </b-link>
          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Volver a login
            </b-link>
          </p>
        </b-card>
        <form-wizard
          color="#8dd852"
          :title="null"
          :subtitle="null"
          layout="vertical"
          next-button-text="Siguiente"
          finish-button-text="Enviar"
          back-button-text="Anterior"
          class="wizard-vertical mb-3"
          @on-complete="formSubmitted"
        >
          <!-- confirmar email -->
          <tab-content
            title="Ingresa tu correo"
            :before-change="validationForm"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    ¿Olvidaste tu contraseña? 🔒
                  </h5>
                  <small class="text-muted">
                    No tienes porqué preocuparte, ingresa tu email y te enviaremos un código para cambiar tu contraseña.
                  </small>
                </b-col>
                <b-col md="6">
                  <b-form @submit.prevent="validationForm">
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="email"
                          v-model="userEmail"
                          placeholder="john@example.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <!-- Código de acceso -->
          <tab-content
            title="Validación de token"
            :before-change="validationCode"
          >
            <validation-observer
              ref="codeRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Ingresa tu código 📝
                  </h5>
                  <small class="text-muted">
                    Lo estas haciendo genial, pronto estarás devuelta.
                  </small>
                </b-col>
                <b-col md="6">
                  <b-form @submit.prevent="validationCode">
                    <b-form-group
                      label="Código"
                      label-for="code"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Código"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="code"
                          v-model="userCode"
                          placeholder="ej. 78423"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <!-- Cambio de contraseña -->
          <tab-content
            title="Cambiar contraseña"
            :before-change="validationPassword"
          >
            <validation-observer
              ref="passwordRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Nueva Contraseña 🔒
                  </h5>
                  <small class="text-muted">
                    Ahora solo debes ingresar una nueva contraseña, recuerda que esta debe ser diferente a la anterior.
                  </small>
                </b-col>
                <b-col md="6">
                  <b-form @submit.prevent="validationCode">
                    <!-- password -->
                    <b-form-group
                      label="Nueva contraseña"
                      label-for="reset-password-new"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Contraseña"
                        vid="Password"
                        rules="required|password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="reset-password-new"
                            v-model="password"
                            :type="password1FieldType"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"
                            name="reset-password-new"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="password1ToggleIcon"
                              @click="togglePassword1Visibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- confirm password -->
                    <b-form-group
                      label-for="reset-password-confirm"
                      label="Confirmar contraseña"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="confirmar contraseña"
                        rules="required|confirmed:Password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="reset-password-confirm"
                            v-model="cPassword"
                            :type="password2FieldType"
                            class="form-control-merge"
                            :state="errors.length > 0 ? false:null"
                            name="reset-password-confirm"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="password2ToggleIcon"
                              @click="togglePassword2Visibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import KultiverLogo from '@core/layouts/components/Logo.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    KultiverLogo,
  },
  data() {
    return {
      required,
      email,
      userEmail: '',
      userCode: '',
      password: '',
      cPassword: '',
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationCode() {
      return new Promise((resolve, reject) => {
        this.$refs.codeRules.validate().then(success => {
          if (success) {
            if (this.userCode === '12345') {
              resolve(true)
            } else {
              this.makeToast('danger', 'ERROR', 'Código Incorrecto')
              reject()
            }
          } else {
            this.userCode = ''
            reject()
          }
        })
      })
    },
    validationPassword() {
      return new Promise((resolve, reject) => {
        this.$refs.passwordRules.validate().then(success => {
          if (success) {
            this.makeToast('success', 'ÉXITO', 'El cambio fue realizado')
            this.$router.push({ name: 'login' })
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>
